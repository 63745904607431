<template>
  <div id="orderAdd">
    <Drawer
      :mask-closable="false"
      :transfer="false"
      @on-close="close"
      class="bk-sub-drawer order-add-drawer"
      title="委托单[新增]"
      v-model="flag"
      width="660"
    >
      <div class="bk-drawer-body">
        <Form
          :label-width="110"
          :model="formd"
          :rules="rules"
          @submit.native.prevent
          inline
          ref="form"
        >
          <FormItem label="状态：">{{ $designConst.order.Status[formd.Status] }}</FormItem>
          <FormItem label="委托单号：">
            <Input placeholder="默认由系统生成" v-model="formd.Code" />
          </FormItem>
          <FormItem label="项目工程师：" prop="PartybManagerId" required>
            <Input
              :value="formd.PartybManagerName"
              @on-clear="unPickUser('pick-party-b-manager')"
              clearable
              readonly
            >
              <Button @click="toPickUser('pick-party-b-manager')" icon="ios-more" slot="append"></Button>
            </Input>
          </FormItem>
          <FormItem label="客服人员：" prop="PartybMarketerId" required>
            <Input
              :value="formd.PartybMarketerName"
              @on-clear="unPickUser('pick-party-b-marketer')"
              clearable
              readonly
            >
              <Button @click="toPickUser('pick-party-b-marketer')" icon="ios-more" slot="append"></Button>
            </Input>
          </FormItem>
          <FormItem label="单据时间：" prop="BillDate" required>
            <DatePicker style="width: 100%" v-model="formd.BillDate" />
          </FormItem>
          <FormItem label="实验室：" prop="LabId" required>
            <Select v-model="formd.LabId">
              <span slot="prefix">
                <Icon class="bk-load" type="ios-loading" v-if="LabLoading"></Icon>
              </span>
              <Option :key="item.Id" :value="item.Id" v-for="item in LabLst">{{item.Name}}</Option>
            </Select>
          </FormItem>
          <FormItem label="测试目的：" prop="TestPurpose" required>
            <Select style="width: 100%" v-model="formd.TestPurpose">
              <Option
                :key="item.key"
                :value="item.name"
                v-for="item in $designConst.order.TestPurposeLst"
              >{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="测试标准：">
            <Input v-model="formd.TestStandards" />
          </FormItem>
          <FormItem class="long" label="测试项目：" prop="TestItems" required>
            <Input v-model="formd.TestItems" />
          </FormItem>
          <FormItem class="long" label="测试要求：">
            <Input type="textarea" v-model="formd.TestRequirements" />
          </FormItem>
          <Divider />
          <FormItem label="委托单位：" prop="ClientId" required>
            <Select filterable v-model="formd.ClientId">
              <Option :key="item.Id" :value="item.Id" v-for="item in clientLst">{{ item.Name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="单位地址：">
            <Input v-model="formd.OfficeAddress" />
          </FormItem>
          <FormItem label="送检人：">
            <Input v-model="formd.PartyaTechPrincipal" />
          </FormItem>
          <FormItem label="送检人电话">
            <Input v-model="formd.Tel" />
          </FormItem>
          <FormItem label="送检人传真">
            <Input v-model="formd.Fax" />
          </FormItem>
          <FormItem label="送检人邮箱">
            <Input v-model="formd.Email" />
          </FormItem>
          <Divider />
          <FormItem label="合同单位：">
            <Input v-model="formd.ContractCorpName" />
          </FormItem>
          <FormItem label="法定代表人：">
            <Input v-model="formd.ContractLegalPerson" />
          </FormItem>
          <FormItem label="付款联系人：">
            <Input v-model="formd.BillingContact" />
          </FormItem>
          <FormItem label="付款联系方式：">
            <Input v-model="formd.BillingContactInfo" />
          </FormItem>
          <FormItem class="long" label="合同邮寄地址：">
            <Input placeholder="地址、收件人、邮编" v-model="formd.ContractPostAddress" />
          </FormItem>
          <FormItem class="long" label="报告邮寄地址：">
            <Input v-model="formd.ReportPostAddress" />
          </FormItem>
          <FormItem label="发票类型：">
            <Select v-model="formd.InvoiceType">
              <Option
                :key="item.key"
                :value="item.key"
                v-for="item in $designConst.order.InvoiceTypeLst"
              >{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="发票内容：">
            <Select v-model="formd.InvoiceText">
              <Option label="技术服务费" value="技术服务费"></Option>
              <Option label="检查费" value="检查费"></Option>
            </Select>
          </FormItem>
          <FormItem label="是否要报告：">
            <RadioGroup v-model="formd.Isreport">
              <Radio :label="0">
                <Tag color="default">否</Tag>
              </Radio>
              <Radio :label="1">
                <Tag color="blue">是</Tag>
              </Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="报告中可放图片：">
            <RadioGroup v-model="formd.Ispicreport">
              <Radio :label="0">
                <Tag color="default">否</Tag>
              </Radio>
              <Radio :label="1">
                <Tag color="blue">是</Tag>
              </Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="取报告方式：">
            <RadioGroup v-model="formd.ReportPostWay">
              <Radio
                :key="item.key"
                :label="item.name"
                v-for="item in $designConst.order.ReportPostWayLst"
              >{{ item.name }}</Radio>
            </RadioGroup>
          </FormItem>
          <FormItem label="样品处理方式：">
            <Select v-model="formd.PrototypeProcessWay">
              <Option
                :key="item.key"
                :value="item.key"
                v-for="item in $designConst.order.PrototypeProcessWayLst"
              >{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem class="long" label="受委托方声明：">
            <Input type="textarea" v-model="formd.Statement" />
          </FormItem>
          <FormItem class="long" label="其他情况说明：">
            <Input type="textarea" v-model="formd.ClientOtherDescription" />
          </FormItem>
        </Form>
      </div>
      <div class="bk-drawer-footer">
        <Button
          :loading="posting"
          @click="onOk"
          class="ok-btn"
          icon="md-checkmark"
          title="新增"
          type="primary"
        >新增</Button>
      </div>
    </Drawer>
    <pick-user @on-ok="onPickUser" ref="PickUser" type="1" />
  </div>
</template>
<script>
import PickUser from "@/components/PickUser.vue";
import SelectLabId from "@/components/select-labid.js";
export default {
  components: { PickUser },
  mixins: [SelectLabId],
  data() {
    return {
      flag: false,
      formd: { Status: 0 },
      rules: {
        Code: [{ required: true, message: " " }],
        PartybManagerId: [{ required: true, type: "number", message: " " }],
        PartybMarketerId: [{ required: true, type: "number", message: " " }],
        BillDate: [
          {
            validator: (rule, value, callback) => {
              this.formd.BillDate ? callback() : callback(new Error(" "));
            },
          },
        ],
        LabId: [{ required: true, type: "number", message: " " }],
        TestPurpose: [{ required: true, message: " " }],
        TestItems: [{ required: true, message: " " }],
        ClientId: [{ required: true, type: "number", message: " " }],
      },
      clientLst: [],
      posting: false,
      action: "",
    };
  },
  methods: {
    open() {
      this.flag = true;
      this.reqLab();
      this.reqTrader();
    },
    close() {
      this.flag = false;
      this.posting = false;
      this.$refs.form.resetFields();
      this.formd = { Status: 0 };
      this.clientLst = [];
      this.action = "";
    },
    reqTrader() {
      this.$axios
        .get("/Api/BaseTrader/QueryPg", {
          params: { PageIndex: 1, PageSize: 99, Type: 1 },
        })
        .then((res) => {
          if (res.result) {
            this.clientLst = res.data.DataSet;
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.$Message.error(err.errormsg);
        });
    },
    onOk() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.postData();
        }
      });
    },
    postData() {
      this.posting = true;
      let param = this.$util.copy(this.formd);
      delete param.BillDate;
      if (this.formd.BillDate && typeof this.formd.BillDate == "object") {
        param.BillDate = this.formd.BillDate.format("yyyy-MM-dd");
      }
      this.$axios
        .post("/Api/BtmsOrder/Add", param)
        .then((res) => {
          this.posting = false;
          if (res.result) {
            this.$Message.success("新增成功");
            this.close();
            this.$emit("on-add");
          } else {
            if (res.errormsg) {
              this.$Message.error(res.errormsg);
            }
          }
        })
        .catch((err) => {
          this.posting = false;
          this.$Message.error(err.errormsg);
        });
    },
    // 选择人员
    toPickUser(action) {
      this.action = action;
      let def = [];
      if (this.action == "pick-party-b-manager") {
        if (this.formd.PartybManagerId) {
          def = [
            {
              Id: this.formd.PartybManagerId,
              UserName: this.formd.PartybManagerName,
            },
          ];
        }
      } else if (this.action == "pick-party-b-marketer") {
        if (this.formd.PartybMarketerId) {
          def = [
            {
              Id: this.formd.PartybMarketerId,
              UserName: this.formd.PartybMarketerName,
            },
          ];
        }
      }
      this.$refs.PickUser.open(def);
    },
    onPickUser(lst) {
      console.log(lst);
      if (this.action == "pick-party-b-manager") {
        if (lst[0]) {
          this.formd.PartybManagerId = lst[0].Id;
          this.$set(this.formd, "PartybManagerName", lst[0].UserName);
        } else {
          this.formd.PartybManagerId = "";
          this.$set(this.formd, "PartybManagerName", "");
        }
      } else if (this.action == "pick-party-b-marketer") {
        if (lst[0]) {
          this.formd.PartybMarketerId = lst[0].Id;
          this.$set(this.formd, "PartybMarketerName", lst[0].UserName);
        } else {
          this.formd.PartybMarketerId = "";
          this.$set(this.formd, "PartybMarketerName", "");
        }
      }
    },
    unPickUser(action) {
      if (action == "pick-party-b-manager") {
        this.formd.PartybManagerId = "";
        this.$set(this.formd, "PartybManagerName", "");
      } else if (action == "pick-party-b-marketer") {
        this.formd.PartybMarketerId = "";
        this.$set(this.formd, "PartybMarketerName", "");
      }
    },
  },
};
</script>
<style lang="less">
.order-add-drawer {
  .ivu-form-item {
    margin: 0 10px 10px 0;
    padding: 0;
    width: 300px;
  }
  .ivu-form-item.long {
    width: 610px;
  }
  .ivu-form-item-label {
    font-size: 12px;
  }
  .ivu-divider-horizontal {
    margin: 15px 0 15px;
  }
}
</style>